import {
  getPersistedState,
  persistState,
} from '@/state-utils/persisted-state.ts';
import { makeVar } from '@apollo/client';

export const StreamsState = {
  visited: 'visited',
  unvisited: 'unvisited',
} as const;
export type StreamsState = (typeof StreamsState)[keyof typeof StreamsState];

const streamsKey = 'streams-page-state';

export const streamsState = makeVar<StreamsState | undefined>(
  getPersistedState(streamsKey, StreamsState, undefined),
);

export const getStreamsState = () => {
  if (!streamsState()) {
    const state = getPersistedState(streamsKey, StreamsState, undefined);
    streamsState(state);
  }
  return streamsState();
};

export const setStreamsState = (state: StreamsState) => {
  streamsState(state);
  persistState(streamsKey, state);
};
